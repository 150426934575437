import React from "react";
import DocumentForm from "./DocumentForm";
import DocumentView from "./DocumentView";
import "./document-edit.scss";
import "../KnowledgeBase/knowledgebase.scss";
import { CircularProgress } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";

export default class DocGen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewUrl: null,
      documentId: -1,
      idToken: null,
      wait: false,
      updateIntervalId: null,
      user: undefined,
      sections: [],
      expanded: "",
      currentSection: null,
      isOpenNavPanel: false
    };

    this.triggerWait = this.triggerWait.bind(this);
    this.startCheckInterval = this.startCheckInterval.bind(this);
    this.updateUserStatus = this.updateUserStatus.bind(this);
    this.setPreviewUrl = this.setPreviewUrl.bind(this);
  }

  setPreviewUrl(newUrl, documentId, doc_url) {
    let prevState = { ...this.state };
    prevState.previewUrl = newUrl;
    prevState.documentId = documentId;
    prevState.docxUrl = doc_url;
    this.setState(prevState);
  }

  startCheckInterval() {
    let me = this;
    let id = setInterval(me.updateUserStatus, 2000);
    let prevState = {... me.state};
    prevState.updateIntervalId = id;
    this.setState(prevState);
  }

  updateUserStatus() {
    // alert(8);
    let me = this;
    let prevState = this.state;

    if (document.user === undefined) {
      console.log("user undefined");
    }

    if (document.user !== undefined) {
      prevState.user = document.user;
      me.setState(prevState, (e) => {
        clearInterval(prevState.updateIntervalId);
      });
    }
  }

  async componentDidMount() {
    let me = this;
    this.loadSections().then(() => {
      me.startCheckInterval();
    });
  }

  async loadSections() {
    let me = this;
    let prevState = { ...this.state };
    return new Promise((resolve) => {
      const url = "/document_templates/all";
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          // console.log(e);
          prevState.sections = [...e];
          me.setState(prevState, () => {
            resolve(e);
          });
          // me.setState({ projects: [...e.projects] });
        });
    });
  }

  handleChange = (panel, id) => (event, isExpanded) => {
    let me = this;
    let prevState = { ...me.state };
    if (isExpanded) {
      prevState.expanded = panel;
    } else {
      prevState.expanded = false;
    }
    if (id) {
      prevState.currentSection = me.state.sections.find(
        (section) => section.id === id
      );
    }
    me.setState(prevState);
  };

  openDocument = (spravkaId) => {
    window.location.pathname = `/document_templates/${spravkaId}`;
  };

  togglePanel = (isOpen) => {
    let me = this;
    let prevState = { ...me.state };
    prevState.isOpenNavPanel = isOpen;
    me.setState(prevState);
  };

  // onKeycloakEvent(e) {
  // 	// alert(e);
  // }

  // onKeycloakTokens(e) {
  // 	// alert(JSON.stringify(e));
  // 	if (e.idToken) {
  // 		let prevState = {... this.state};
  // 		prevState.idToken = e.idToken;
  // 		this.setState(prevState);
  // 	}
  // }

  triggerWait() {
    let prevState = { ...this.state };
    prevState.wait = !prevState.wait;
    this.setState(prevState);
  }

  render() {
    let me = this;
    return (
      <div
        style={{
          display: "flex",
          paddingLeft: "35px",
          paddingRight: "35px",
        }}
      >
        {me.state.user === undefined && (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "16px",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {me.state.user && !me.state.isOpenNavPanel && (
            <div
                style={{paddingLeft: "5px"}}
                className="expand-templates-btn"
                onClick={() => me.togglePanel(true)}
            >
              <svg
                  width="20"
                  height="24"
                  viewBox="0 0 20 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.5 3C19.5 1.6193 18.3807 0.5 17 0.5H3C1.6193 0.5 0.5 1.6193 0.5 3V21C0.5 22.3807 1.6193 23.5 3 23.5H17C18.3807 23.5 19.5 22.3807 19.5 21V3ZM3 2H17C17.5523 2 18 2.4477 18 3V21C18 21.5523 17.5523 22 17 22H3C2.4477 22 2 21.5523 2 21V3C2 2.4477 2.4477 2 3 2ZM14 16.75C14.4142 16.75 14.75 17.0858 14.75 17.5C14.75 17.8797 14.4678 18.1935 14.1018 18.2432L14 18.25H6C5.5858 18.25 5.25 17.9142 5.25 17.5C5.25 17.1203 5.5322 16.8065 5.8982 16.7568L6 16.75H14ZM14.75 14.5C14.75 14.0858 14.4142 13.75 14 13.75H6L5.8982 13.7568C5.5322 13.8065 5.25 14.1203 5.25 14.5C5.25 14.9142 5.5858 15.25 6 15.25H14L14.1018 15.2432C14.4678 15.1935 14.75 14.8797 14.75 14.5ZM8 5C9.6569 5 11 6.3431 11 8C11 9.6569 9.6569 11 8 11C6.3431 11 5 9.6569 5 8C5 6.3431 6.3431 5 8 5ZM6.5 8C6.5 7.1716 7.1716 6.5 8 6.5C8.8284 6.5 9.5 7.1716 9.5 8C9.5 8.8284 8.8284 9.5 8 9.5C7.1716 9.5 6.5 8.8284 6.5 8Z"
                    fill="#6360FF"
                />
              </svg>
              <span style={{marginLeft: "6px", color: "#5354da", fontWeight: 600}}>ШАБЛОНЫ</span>
              <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.29289 25.7071C8.90237 25.3166 8.90237 24.6834 9.29289 24.2929L18.5858 15L9.2929 5.70711C8.90237 5.31658 8.90237 4.68342 9.2929 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289L20.7071 14.2929C21.0976 14.6834 21.0976 15.3166 20.7071 15.7071L10.7071 25.7071C10.3166 26.0976 9.68342 26.0976 9.29289 25.7071Z"
                    fill="#6360FF"
                />
              </svg>
            </div>
        )}
        {me.state.isOpenNavPanel && (
            <div
                style={{
                  minWidth: "348px",
              height: "fit-content",
              background: "#FFFFFF",
              border: "1px solid #EAEAF9",
              boxSizing: "border-box",
              borderRadius: "20px",
              marginRight: "10px",
              padding: "0px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: "28px",
                    lineHeight: "40px",
                    color: "#6360FF",
                  }}
                >
                  Выберите шаблон
                </Typography>
              </div>
              <div
                style={{ display: "flex" }}
                onClick={() => me.togglePanel(false)}
              >
                <svg
                  width="12"
                  height="22"
                  viewBox="0 0 12 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L2.41421 11L11.7071 20.2929C12.0976 20.6834 12.0976 21.3166 11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071L0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893Z"
                    fill="#6360FF"
                  />
                </svg>
              </div>
            </div>
            <div>
              {me.state.sections.map((section) => (
                <Accordion
                  // expanded={me.state.expanded === `panel-${section.id}`}
                  onChange={me.handleChange(`panel-${section.id}`, section.id)}
                  className="knowledgebase-navigation"
                  key={`section-${section.id}`}
                >
                  <AccordionSummary key={`summary-${section.id}`}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color:
                          me.state.expanded === `panel-${section.id}`
                            ? "#5354DA"
                            : "#6360FF",
                      }}
                    >
                      {section.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails key={`details-${section.id}`}>
                    <div style={{ display: "block" }}>
                      {section.documents.map((spravka) => (
                        <Typography
                          onClick={() => me.openDocument(spravka.id)}
                          style={{
                            color:
                              window.location.pathname ==
                              `/document_templates/${spravka.id}`
                                ? "#3E3E3E"
                                : "#C1C1C1",
                            fontSize: "14px",
                            lineHeight: "20px",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                          key={`${section.id} - ${spravka.id}`}
                        >
                          {spravka.name}
                        </Typography>
                      ))}
                      <div
                        style={{
                          width: "10px",
                          background: "#6360ff",
                          height: "100%",

                          transition:
                            "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          opacity: "1 !important",
                          position: "absolute",
                          left: "-30px",
                          top: "0px",
                          borderTop: "1px solid #F5F5FF",
                          borderBottom: "1px solid #F5F5FF",
                        }}
                      ></div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        )}
        {me.state.user && (
          <div
            style={{
              // transition: "400ms",
              // pointerEvents: me.state.wait ? "none" : "all",
              // opacity: me.state.wait ? "0.8" : 1,
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <DocumentForm
              triggerWait={me.triggerWait}
              setPreviewUrl={me.setPreviewUrl}
              document_template={this.props.document_template}
              fields={me.props.fields}
            />
            <DocumentView
              docxUrl={me.state.docxUrl}
              documentId={me.state.documentId}
              key={`preview-${me.state.previewUrl}`}
              previewUrl={me.state.previewUrl}
              document_template={this.props.document_template}
            />
          </div>
        )}
      </div>
    );
  }
}
